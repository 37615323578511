<template>
  <div class="login-container">
    <el-container>
      <el-main class="main-container">
        <el-card
          class="login-card"
          shadow="never"
          :body-style="cardCss">
          <h2>多彩C端登入</h2>
          <div class="login-content">
            <el-input
              placeholder="請輸入帳號"
              v-model="query.username"
              @keyup.enter.native="handleLogin" />
            <el-input
              placeholder="請輸入密碼"
              show-password
              v-model="query.password"
              @keyup.enter.native="handleLogin" />
          </div>
          <el-button
            type="primary"
            @click="handleLogin">
            Login
          </el-button>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { consoleLogin } from '@/api/admin/console'
import { goPage } from '@/utils/route'

export default {
  data () {
    return {
      query: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    cardCss: {
      get () {
        return {
          display: 'flex',
          'flex-direction': 'column',
          gap: '15px'
        }
      }
    }
  },
  methods: {
    handleLogin () {
      consoleLogin(this.query)
        .then(res => {
          if (res.status !== resStatus.OK) {
            this.$message.error(res.message)
          } else {
            this.handleLoginSuccess()
          }
        })
        .catch(err => {
          // console.error(err)
          this.$message.error(err)
        })
    },
    handleLoginSuccess () {
      goPage({
        name: 'AdminHome'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEEF5;
  .main-container {
    display: flex;
    justify-content: center;
    .login-card {
      max-width: 400px;
      width: 100%;
      h2 {
        font-size: 16px;
        color: #4f4f4f;
      }
      .login-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
.el-button--primary:focus {
  outline-color: #66b1ff;;
}
</style>
